@import '~carbon-components/scss/globals/scss/vars';
@import '~carbon-components/scss/globals/scss/typography';

.event-tile {
  background: $ui-02;
  h1 {
    @include carbon--type-style('productive-heading-02');
  }
  h2 {
    color: $ui-04;
  }
  .centered {
    display: flex;
    align-items: center;
    margin-top: $spacing-02;
    margin-bottom: $spacing-02;
    font-weight: 400;
  }
  .padded {
    margin-left: $spacing-05;
    display: flex;
    align-items: center;
  }
  .overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .actions-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem .5rem 0 1rem;
  }
  .metadata {
    margin-top: $spacing-04;
  }
  .bx--accordion__item {
    border-top: none;
  }
  .bx--accordion__content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .bx--structured-list {
    margin-bottom: 0;
  }
}
