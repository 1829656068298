@import '~carbon-components/scss/globals/scss/colors';

.filter-checkbox {
  display: flex;
  align-items: center;
  position: relative;

  .filter-count {
    margin-right: 5px;
    width: 20px;
    text-align: right;
    color: $gray-30;
  }

  .filter-bar {
    height: 20px;
    position: absolute;
    left: 50px;
    transition: width 0.5s cubic-bezier(0.39, 0.58, 0.57, 1);
  }

  span {
    font-size: 12px;
  }

  .bx--overflow-menu, .bx--overflow-menu__trigger {
    width: 1.5rem;
    height: 1.5rem;
  }
}
