.user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  .circle {
    background: #dadada;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
  }
}
