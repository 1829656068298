.bleed {
  background: white;
  margin-left: -40px;
}

.login-view {
  //padding: 0 !important;
  height: 100vh;

  img {
    width: 45%;
  }

  .bx--row {
    height: inherit;
    margin: 0;
  }

  .background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .login-form {
    padding: 4rem;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
