#termsAndConditionsModal {

  .bx--modal-content {
    padding-right: 20px;
  }

  .terms-box {
    max-height: 400px;
    overflow: auto;

    h1 {
      margin: 1rem 0 1rem 0;
      font-size: 1.2rem;
      font-weight: 800;
    }

    h2 {
      margin: 1rem 0 1rem 0;
      font-size: 1rem;
      font-weight: 600;
    }

    p {
      line-height: 1.6rem;
    }
  }

}
