.widgets-container {
  &__container {
    padding: 32px;

    &._not-resizable {
      padding: 32px 32px 0 32px;
      border-right: none;
    }
  }

  &__item {
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 16px;
    position: relative;
    overflow: hidden;

    &-wrapper {
      position: relative;
    }

    &._not-resizable {
      border: none;
      border-radius: 0;
    }
  }
}
