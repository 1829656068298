.competing-reference-architecture-legend {
  display: flex;
  margin: 20px 0 20px 0;

  .legend-item {
    display: flex;
    margin-right: 20px;

    .legend-item-circle {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
