@import '~carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family';
@import '~carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint';
@import '~carbon-components/scss/globals/scss/typography';
@import '~carbon-components/scss/globals/scss/vars';

.company-view {
  &__navigation-bar {
    margin-top: -15px;
    .bx--row  {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      padding: 1rem;
      border-bottom: 1px solid #e2e2e2;
    }
  }
  .competitor-list {
    margin-left: 10px;
    width: 300px;
  }
}

.company-information__logo-name {
  margin-top: 20px;
  display: flex;

  .company-information__logo {
    margin-right: 20px;
    background: white;
    padding: 1rem;
    border: 1px solid #e2e2e2;
    img {
      object-fit: contain;
    }
  }
}

.company-view__about-facts {
  margin-top: 20px;
  li {
    margin-bottom: 10px;
  }
}

.company-view__information-section {
  height: 450px;
}

.company-view__top-panel {
  padding: 0.5rem;
}

.company-view__services-section {
  margin-top: 3rem;
}

.company-view-logo {
  position: relative;
  object-fit: contain;
}

.company-view-logo[alt]:after {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background-color: #ececec;
  content: '';
}

.company-view-logo__image-placeholder {
  width: 100px;
  height: 100px;
  background: #7c7c7c;
}

.company-view-momentum__overall-score {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin: 1rem 0 1rem 0;
  border-radius: 3px;
}

.company-view__section-wrapper {
  background: white;
  padding: 1.5rem;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}

