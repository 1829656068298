.widget-button {
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 99;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -12px;
  right: 30px;
  cursor: pointer;
  color: white;
}

.remove-widget-button {
  background: #FF5964;
}

.add-widget-button {
  background: #4DCCBD;
}
