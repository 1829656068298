.comment-list {
  .comment-list-item {
    margin-bottom: 2rem;
    .comment-list-item__header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .comment-list-item__username {
      margin: 0 10px 0 10px;
    }
  }
}
