@import '~carbon-components/scss/globals/scss/typography';

.card-company-section {
  background: white;
  border: 1px solid #e2e2e2;

  &__header {
    display: flex;
    align-items: center;
    line-height: 1.625rem;
    letter-spacing: 0;
    border-bottom: 1px solid #e2e2e2;
    padding: 0.5rem;
    text-transform: uppercase;
    color: grey;
    font-size: 14px;
    font-weight: 400;
  }

  &__body {
    padding: 0.5rem;
  }
}
