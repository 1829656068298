@import '~carbon-components/scss/globals/scss/vendor/@carbon/type/scss/font-family';
@import '~carbon-components/scss/globals/scss/vendor/@carbon/layout/scss/breakpoint';
@import '~carbon-components/scss/globals/scss/typography';
@import '~carbon-components/scss/globals/scss/vars';
@import '~carbon-components/scss/globals/scss/colors';

a {
  &:hover {
    text-decoration: none !important;
  }
}

// Common utility classes

.bg-white {
  background: white;
}

.padding-large {
  padding: 1rem;
}

.no-gutter {
  padding: 0 1rem 0 1rem;
}

.no-gutter-right {
 padding-right: 0;
}

.main-view-layout {
  padding-top: $spacing-10;
}

.main-view__heading {
  padding-top: $spacing-05;
  @include carbon--type-style('productive-heading-05');
}

.main-view__subheading {
  padding-top: $spacing-03;
  @include carbon--type-style('productive-heading-03');
}

.main-view__subheading2 {
  padding-top: $spacing-03;
  @include carbon--type-style('productive-heading-01');
  color: $gray-50;
}

.margin-top-small {
  margin-top: $spacing-02;
}

.margin-top-large {
  margin-top: $spacing-05;
}

.margin-bottom-large {
  margin-bottom: $spacing-05;
}

.bx--modal-content {
  height: 100%;
}

.bx--modal-header {
  padding-bottom: 24px;
}
