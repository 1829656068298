.architecture-view {
  &__item {
    display: flex;
    position: relative;

    &-container {
      display: flex;
      flex-direction: column;
      width: max-content;
    }

    &-title-wrapper {
      writing-mode: tb-rl;
      text-orientation: mixed;
      display: flex;
      justify-content: center;
      padding: 8px 24px;
      font-size: 18px;
      font-weight: 800;
      transform: rotateZ(180deg);
      background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
      color: white;
      border-bottom: none;
      width: 80px;
      align-items: center;
    }

    &-title {
      max-height: 155px;
    }
  }

  &__row-container {
    border-collapse: collapse;
    margin: 0;
    box-sizing: border-box;
  }

  &__cell {
    height: 100px;
    width: 100px;
    border: #bbcbda 2px solid;
    max-width: 100px;
    overflow: hidden;
    white-space: normal;
    word-break: break-word;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    padding: 4px 8px;
    cursor: pointer;
    transition: background-color 0.5s cubic-bezier(0.39, 0.58, 0.57, 1);

    &._active {
      outline: black 2px auto;
      background: #FF5964 !important;
    }

    &._head {
      width: 200px;
      max-width: 200px;
      font-weight: 800;
      font-size: 18px;
    }
  }
}
