@import "../../../Assets/variables";

.widget-drawer {
  &__wrapper {
    flex-shrink: 0;
    transition: right .5s ease-in-out;
    transition-delay: 0s;
    background: white;
    position: absolute;
    right: 0;
    height: $maximum-viewport-height - 5; // To account for the Edit Widgets button
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    border: 1px solid $market-explorer-border-color;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
  }

  &__widgets-container {
    display: flex;
    height: 100%;
  }

  &__edit-enable-button {
    width: 135px;
    font-size: 14px;
    padding: 8px;
    color: #0E61FE;
    cursor: pointer;
    position: absolute;
    text-align: center;
    margin-right: 32px;
    font-weight: bold;

    &._active {
      background: linear-gradient(0deg, rgba(14, 97, 254, 0.1), rgba(14, 97, 254, 0.1)), #FFFFFF;
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.25);
    }
  }
}
