.table-item__image-placeholder {
  width: 30px;
  height: 30px;
  background: #7c7c7c;
}

.table-market-explorer-image {
  position: relative;
  object-fit: contain;
  margin-left: 10px;
}

.table-market-explorer-image[alt]:after {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background-color: #ececec;
  content: '';
}

.table-market-explorer__momentum-cell {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.table-market-explorer {
  .bx--table-expand {
    padding-left: 10px !important;
  }
}

.user-tag-empty {
  height: 30px;
  width: 100%;
  &:hover {
    background: white;
  }
}

.new-comment-empty {
  width: 300px;
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  color: grey;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
  &:hover {
    background: white;
  }
}

.logo-cell-inner {
  display: flex;
  align-items: center;
}
