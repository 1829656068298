@import "../../Assets/variables";

.market-explorer-view {
  max-height: 100vh - 15;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__navigation-bar {
    margin-top: -15px;
    .bx--row  {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      padding: 0 20px 0 20px;
      border-bottom: 1px solid #e2e2e2;
    }
  }

  &__tab-content {
    display: flex;
    height: 100%;
  }

  &__main-tab-content {
    flex-grow: 1;
  }
}

.filter-bar-wrapper {
  height: $maximum-viewport-height;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid $market-explorer-border-color;
  background: white;
  padding: 1rem;
}

.content {
  flex-grow: 2;
  background: white;
  margin-left: -16px;
  max-height: $maximum-viewport-height;
  flex-direction: column;
  overflow-x: auto;
}

.sidebar {
  background: white;
  min-width: 400px;
  width:100%;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 1;
  position: relative;
}

.inactive-widgets-sidebar {
  background: white;
  min-width: 400px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  flex-grow: 1;
  position: relative;
  height: 87vh;
}

.custom-handle {
  cursor: ew-resize;
  width: 5px;
  height: 80px;
  margin: 0px -5px;
  background: #b7b7b7;
  text-align: center;
  z-index: 99999;
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: 1rem;
}

.custom-resize-border {
  cursor: ew-resize;
  background: #d2d2d2;
  display: flex;
  z-index: 99999;
  align-items: center ;
  align-content: center ;
  justify-content: center;
  overflow: visible;
}
