.favourite-button {
  cursor: pointer;
}

.filled-favourite {
  color: #ffb74d;
}

.filled-forestreet-favourite {
  color: #337CFF;
}
