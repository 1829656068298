@import '~carbon-components/scss/globals/scss/vars';
@import '~carbon-components/scss/globals/scss/typography';
@import '~carbon-components/scss/globals/scss/colors';

.card-with-image__body {
  padding: $spacing-03;

  h2 {
    @include carbon--type-style('productive-heading-03');
    font-weight: bold;
  }

  h3 {
    @include carbon--type-style('productive-heading-02');
    color: $gray-60;
  }

  p {
    @include carbon--type-style('body-short-01');
    margin-top: $spacing-03;
    margin-bottom: $spacing-03;
  }

  button {
    margin-top: $spacing-03;
  }
}

.card-with-image__body-small {
  margin-top: $spacing-03;
  @include carbon--type-style('label-01');
  color: $gray-50;
}

.card-with-image__image {
  width: 100%;
}

.card-with-image__footer {
  margin-top: $spacing-03;
  margin-bottom: $spacing-03;
}

.card-image-wrapper {
  height: 200px;
  width: 100%;
}

.card-with-image__image-placeholder {
  width: 30px;
  height: 30px;
  background: #7c7c7c;
}

.card-with-image-image[alt]:after {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: #b9b9b9;
  content: '';
}

.card-with-image__wrapper {
  transition: all .2s ease-in-out;
  transition-delay: 0s;
  border: 1px solid #e2e2e2;
  &:hover {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
  }
}
