@import '~lineupjs/build/LineUpJS.css';

.lineup-widget {
  flex: 1 1 0;
  height: 100%;
}

.lu-upset-line {
  box-sizing: content-box !important;
}

.lu-renderer-images {
  display: flex;
  flex-wrap: wrap;

  &[data-count="1"] > div {
    width: 100%;
  }

  &[data-count="2"] > div,
  &[data-count="4"] > div {
    width: 50%;
  }

  &[data-count="3"] > div,
  &[data-count="5"] > div,
  &[data-count="6"] > div {
    width: 32%;
  }
}

.lu-upset-small {
  width: 7px;
  height: 7px;
  margin: 4px;
}

.lu-typed-icon::before {
  display: none !important;
}

.action-test::before {
  content: 'T';
}
