.bubble-chart {
  &__wrapper {
    display: flex;
  }

  &__container {
    height: calc(100vh - 200px);
    width: 100%;

    svg {
      filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.4));
    }
  }

  &__control {
    margin-top: 20px;
  }
}
